
import { defineComponent, ref, onMounted, watch, computed } from 'vue';
import ApiService from '@/core/services/ApiService';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { IUser } from '@/core/data/users';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { reinitializeComponents } from '@/core/plugins/keenthemes';
import { useStore } from 'vuex';
import { IUserLevel } from '@/core/data/userLevel';

export default defineComponent({
	name: 'users-listing',
	components: {
		Datatable
	},
	setup() {
		const loading = ref(true);
		const store = useStore();
		const checkedUsers = ref([]);
		const tableHeader = ref([
			{
				key: 'checkbox',
				sortable: false
			},
			{
				name: 'First Name',
				key: 'firstName',
				sortable: true
			},
			{
				name: 'Last Name',
				key: 'lastName',
				sortable: true
			},
			{
				name: 'Email (username)',
				key: 'username',
				sortable: true
			},
			{
				name: 'User Level',
				key: 'level',
				sortable: true
			},
			{
				name: 'Active',
				key: 'active',
				sortable: true
			},
			{
				name: 'Actions',
				key: 'actions'
			}
		]);

		const tableData = ref<Array<IUser>>([]);
		const initUser = ref<Array<IUser>>([]);
		const userLevels = ref<Array<IUserLevel>>([]);

		const selectedCustomer = computed(() => {
			return store.getters.selectedCustomer;
		});

		const paginationHeader = ref<any>({ TotalCount: 0 });

		const pagingFilteration = ref<any>({
			paging: {
				pageNumber: 1,
				pageSize: 10
			},
			filter: {
				searchTerm: '',
				type: ''
			},
			orderBy: {
				orderByField: '',
				orderDesc: false
			}
		});

		const getUsersList = () => {
			loading.value = true;
			ApiService.post(`User/list`, pagingFilteration.value)
				.then(({ data, headers }) => {
					paginationHeader.value = JSON.parse(headers['x-pagination']);
					tableData.value.splice(0, tableData.value.length, ...data);
					initUser.value.splice(0, tableData.value.length, ...data);
					loading.value = false;
					reinitializeComponents();
				})
				.catch(({ response }) => {
					loading.value = false;
					tableData.value.splice(0, tableData.value.length);
					initUser.value.splice(0, tableData.value.length);
					console.log('response', response);
				});
		};

		watch(
			() => pagingFilteration.value.paging.pageSize,
			() => {
				getUsersList();
			},
			{ deep: true }
		);

		watch(
			() => pagingFilteration.value.paging.pageNumber,
			() => {
				getUsersList();
			},
			{ deep: true }
		);

		watch(
			() => pagingFilteration.value.orderBy,
			() => {
				getUsersList();
			},
			{ deep: true }
		);

		const getUserLevels = () => {
			ApiService.get('UserLevel')
				.then(({ data }) => {
					// console.log('UserLevel response: ', data);
					userLevels.value = data;
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};

		const getUserLevelName = id => {
			if (userLevels?.value.length > 0) {
				// console.log(userLevels.value.length);
				const selectedItem = userLevels.value.find(el => el.level === id);
				return selectedItem?.label;
			}
			return id;
		};

		const onOrderByChange = orderQuery => {
			pagingFilteration.value.orderBy.orderByField = orderQuery.columnName;
			pagingFilteration.value.orderBy.orderDesc = orderQuery.order === 'desc';
		};

		const onItemsPerPageChange = itemsPerPage => {
			pagingFilteration.value.paging.pageSize = itemsPerPage;
		};

		const onCurrentChange = currentPage => {
			pagingFilteration.value.paging.pageNumber = currentPage;
		};

		onMounted(() => {
			setCurrentPageBreadcrumbs('Users', []);
			getUserLevels();
			getUsersList();
		});

		const deleteFewUsers = () => {
			checkedUsers.value.forEach(item => {
				deleteData(item);
			});
			checkedUsers.value.length = 0;
		};

		const deleteUser = id => {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				buttonsStyling: false,
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary'
				}
			}).then(result => {
				if (result.isConfirmed) {
					deleteData(id);
				}
			});
		};

		const inviteUser = email => {
			Swal.fire({
				title: 'Send invitation email',
				text: 'An email will be send to ' + email,
				icon: 'warning',
				buttonsStyling: false,
				showCancelButton: true,
				confirmButtonText: 'send now',
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary'
				}
			}).then(result => {
				if (result.isConfirmed) {
					sendEmail(email);
				}
			});
		};

		const deleteData = id => {
			ApiService.delete(`User/${id}`)
				.then(({ data }) => {
					console.log('data', data);
					getUsersList();
					Swal.fire({
						timer: 1500,
						text: 'Data has been successfully deleted!',
						icon: 'success',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
				})
				.catch(({ response }) => {
					console.log('response', response);
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
				});
		};

		const sendEmail = email => {
			ApiService.post(
				`User/sendinvitation?userName=${encodeURIComponent(email)}`,
				email
			)
				.then(({ data }) => {
					console.log('data', data);
					getUsersList();
					Swal.fire({
						timer: 1500,
						text: 'Email has been send to ' + email,
						icon: 'success',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
				})
				.catch(({ response }) => {
					console.log('response', response);
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
				});
		};

		const search = ref<string>('');

		const searchItems = () => {
			getUsersList();
		};

		const searchingFunc = (obj, value): boolean => {
			for (let key in obj) {
				if (
					!Number.isInteger(obj[key]) &&
					!(typeof obj[key] === 'object') &&
					!(typeof obj[key] === 'boolean')
				) {
					if (obj[key].indexOf(value) != -1) {
						return true;
					}
				}
			}
			return false;
		};

		return {
			loading,
			tableData,
			tableHeader,
			deleteUser,
			inviteUser,
			getUserLevels,
			getUserLevelName,
			getUsersList,
			search,
			selectedCustomer,
			searchItems,
			checkedUsers,
			userLevels,
			deleteFewUsers,
			onItemsPerPageChange,
			onCurrentChange,
			onOrderByChange,
			pagingFilteration,
			paginationHeader
		};
	}
});
